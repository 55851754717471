import React from 'react';
import RegisterCandidates from '../components/RegisterCandidates';

function Dev() {
  return (
    <div>
      <RegisterCandidates />
    </div>
  );
}

export default Dev;
